import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createPinia } from 'pinia';
import { useAuthStore } from './store/auth';
import "./assets/style.css"

import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.timeout = 600000

const app = createApp(App);

const pinia = createPinia();
app.use(pinia); // Pinia doit être installé avant d'utiliser un store
app.use(router);
app.use(store);

const authStore = useAuthStore(); // Maintenant, il peut être utilisé

axios.interceptors.request.use(config => {
  const token = authStore.token;

  if (token) {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    if (Date.now() >= exp * 1000) {
      authStore.logout();
      router.push({ name: 'Login' });
      return Promise.reject(new Error('Session expirée'));
    }
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

app.mount('#app');
